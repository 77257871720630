.wave {
  background-image: url('https://squarebric-media-files.squarebric.com/wave.png');
  /* background-image: url('https://squarebric-media-files.squarebric.com/service1.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%; /* Set width to 100% to cover the full width */
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}


@media (max-width: 850px) {
  .header{
    width: 150%;
  }
}
.background{
  background-image: url('https://squarebric-media-files.squarebric.com/gsdfg1.png');
  /* background-image: url('https://squarebric-media-files.squarebric.com/service1.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%; /* Set width to 100% to cover the full width */
}



<<<<<<< HEAD

/* footer.css */
/* .header {
  width: 100%;
  background: var(--blue, #2E4374);
} */
=======
.header {
  width: 100%;
  /* background: var(--blue, #2E4374); */
}
>>>>>>> ee8302833f1e1d32e887d261e5d619e3203d43f9

.flex {
  display: flex;
  justify-content: space-between;
  
}

.hidden {
  display: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.items-center {
  align-items: center;
}

.text-white {
  color: white;
}

.border-none {
  border: none;
}

.bg-none {
  background: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.active\:underline:active {
  text-decoration: underline;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

@media (max-width: 1024px) {
  .lg\:hidden {
    display: none;
  }
  
  .lg\:flex {
    display: flex;
  }
  
  .hidden {
    display: block;
  }
}

@media (min-width: 1025px) {
  .hidden {
    display: none;
  }
}
