


.scrolling {
  background-attachment: fixed;
}

.active {
  padding: 5px 10px;
  background-color: blue;
  color: white;
  border: none;
}

.slider-container {
  display: flex;
  overflow: hidden;
  /* transition: transform 0.30s ease-in-out; */
}

.slider-card {
  flex: 0 0 25%;
  box-sizing: border-box;
}

/* Background.css */

.tooltip {
  position: fixed;
  display: none;
  z-index: 1;
}

.tooltip-text {
  background-color: #f9f9f9;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

/* ---------------- */
.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .first-nav {
    position: absolute;
    top: 60px;
    right: 0;

    background-color: #f0f0f0;
    z-index: 1;
  }

  .first-nav.open {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .first-nav-list {
    flex-direction: column;
    align-items: center;
  }

  .first-nav-item {
    margin: 10px 0;
  }

  .menu-icon {
    margin-right: 20px;
  }
}

.active {
  text-decoration: none;
  list-style: none;
}

.textScreen {
  font-size: '6px';
  margin-top: '4px';
}

.TabButton.active {
  border-bottom: 2px solid blue; /* You can adjust the color and thickness as needed */
}

.tag-container {
  position: absolute;
  top: 50px;
  z-index: 1; /* Ensure the tag appears above the image */
}

.tag {
  padding: 5px 10px;
  border-radius: 5px;
  color: #2e3a71;
  font-size: 12px;
  font-weight: bold;
}

.residential-tag {
  background-color: #d9d9d9cc; /* Red background for residential */
}

.commercial-tag {
  background-color: #d9d9d9cc; /* Blue background for commercial */
}

.customColor {
  color: #20206f;
}

.property-details-container {
  margin-top: 20px;
  margin-right: 16px;
}

.property-card {
  height: 100%;
}

.property-image {
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
}

.navigation-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.navigation-buttons button {
  background-color: #1e2858;
  color: white;
  border: none;
  padding: 8px;
  margin: 0 5px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.responsive-image {
  height: 900px;
  width: 900px;
}

.property-card {
  transition: transform 0.3s ease;
}

.property-card:hover {
  transform: scale(0.8);
}
.tag {
  position: absolute;
  top: 30px;
  
  border-radius: 0px 4px 4px 0px;
  background: rgba(217, 217, 217, 0.60);
  color: '#1E285';
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 10px;
  text-transform: uppercase;
}

/* For mobile devices (up to 640px width) */
@media only screen and (max-width: 640px) {
  .responsive-image {
    width: 500px; /* Adjust the width to fill the container */
    height: 300px; /* Maintain aspect ratio */
  }
}
