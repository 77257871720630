/* CustomToast.css */
.toast {
    position: relative;
    top: 200px;
    right: 20px;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
  }
  
  .toast.success {
    background-color: green;
  }
  
  .toast.error {
    background-color: red;
  }
  
  .toast.hide {
    opacity: 0;
  }
  