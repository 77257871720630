.singlesearch {
  background-image: url('https://squarebric-media-files.squarebric.com/itpark/exterior_view/aerial+view.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  width: 100%;
  display: flex;
  margin: 0 auto;
}


@media (max-width: 600px) {
  .singlesearch {
    font-size: xx-small;
    width: 80vw;
    height: 400px;
  }
}

@media (min-width: 600px)and (max-width: 1024px) {
  .singlesearch {
    width: 75vw;
    height: 400px;
  }
}

@media (min-width: 1025px) and (max-width: 1556px) {
  .singlesearch {
    width: 80%;
    height: 400px;
  }
}



@media (min-width: 1557px) and (max-width: 1920px) {
  .singlesearch {
    height: auto;
    width: 65%;
    height: 400px;
  }
}

@media (min-width: 1921px) and (max-width: 2600px) {
  .singlesearch {
    width: 65%;
    height: 400px;
  }
}



.agent-details {
  display: flex;
  flex-wrap: wrap;
}

.agent-details li {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  padding: 4px;
}

.name {
  flex: 0 0 30%;
  margin-right: 8px;
}

.value {
  flex: 1;
}

.dot-list {
  list-style-type: disc;
}

.sticky {
  position: fixed;
  top: 0;
  width: 75%;
  z-index: 1000;
 
 
}
.sticky {
  position: fixed;
  top: 0;
  left: 50%;
  width: 75%; /* Adjust based on your design */
  transform: translateX(-50%);
  z-index: 1000;
  background-color: linear-gradient(to right, #2196F3, #00BCD4); /* Change this to your preferred gradient */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .sticky {
    width: 80%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .sticky {
    width: 82%;
  }
}

@media (min-width: 1025px) and (max-width: 1556px) {
  .sticky {
    width: 80%;
  }
}

@media (min-width: 1557px) and (max-width: 1920px) {
  .sticky {
    width: 75%;
  }
}

@media (min-width: 1921px) and (max-width: 2600px) {
  .sticky {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .table tbody tr {  /* Target all table rows */
    display: flex;   /* Make rows into flex containers */
    flex-wrap: wrap;  /* Allow rows to wrap on smaller screens */
  }
  .table td {  /* Target all table cells */
    flex: 1;  /* Make cells flexible and grow/shrink as needed */
  }
}




.menu-item:hover {
  background-color: #f9f9f9;
}



/* Add this to Singlesearch.css */
.menu-container {
  overflow: hidden;
  white-space: nowrap;
  transition: transform 0.3s ease;
}

.menu-items {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease;
}

.menu-item {
  display: inline-block;
  min-width: 120px; /* Adjust based on your design */
  margin-right: 20px;
}

.menu-container.slide-left .menu-items {
  transform: translateX(-100%);
}

.menu-container.slide-right .menu-items {
  transform: translateX(100%);
}

@media (max-width: 768px) {
  .content-slider .flex-shrink-0 {
    flex: 0 0 calc(100% / 3); /* Show 3 items at a time on mobile */
  }
}


