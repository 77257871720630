@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* index.css */


.heading::after {
  @apply content-[''] block w-1/2 h-1 bg-[#4BA3DB] absolute bottom-0 left-0;
}

@font-face {
  font-family: 'Libre Caslon Text';
  src: local('Libre Caslon Text'), url('./../public/LibreCaslonText-Bold.ttf') format('truetype');
  font-weight: bold;
}



.underlineresponsive {
  display: inline-block;
  position: relative;
}

.underlineresponsive::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 4px; /* Adjust as needed for underline thickness */
  background-color: #4BA3DB;
  position: absolute;
  bottom: -1px; /* Adjust as needed for space below text */
  left: 0;
}