.featuredmain {
  background-image: url('https://squarebric-media-files.squarebric.com/featured.png');
  /* background-image: url('https://squarebric-media-files.squarebric.com//service1.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%; /* Set width to 100% to cover the full width */
}
.residenceimage {
  width: 500px;
}
.mobileCard {
  width: 450px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Single column layout by default */
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns for screens larger than 768px */
  }
}

.info-block {
  margin-bottom: 10px; /* Add some space between blocks */
}

@media (max-width: 1277px) {
  .mobileCard {
    width: 355px;
  }
}

@media (max-width: 1024px) {
  .mobileCard {
    width: 400px;
  }
  .residenceimage {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .mobileCard {
    width: 380px;
    margin-left: 30px;
  }
  .residenceimage {
    width: 380px;
  }
}

@media (max-width: 500px) {
  .mobileCard {
    width: 300px;
    margin-left: 25px;
    height: 31vh;
  }
  .residenceimage {
    width: 300px;
  }
}
