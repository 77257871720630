@keyframes wiggleText {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .moving-text {
    animation: wiggleText 2s ease-in-out infinite;
  }
  