.gradient-checkbox {
  appearance: none;
  border: 1px solid #ccc;
  width: 18px;
  height: 15px;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.gradient-checkbox::before {
  content: '';
  width: 80%;
  height: 80%;
}

.gradient-checkbox:checked::before {
  content: '';
  width: 30px;
  height: 15px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none"><rect x="0.65625" y="0.085083" width="8.71428" height="8.71428" rx="0.568322" fill="url(%23paint0_linear_7949_16555)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6.56547 2.69596L4.73492 5.11732L3.94556 4.09551H3.94556C3.78107 3.88422 3.47645 3.84628 3.26515 4.01076C3.05386 4.17525 3.01592 4.47987 3.18041 4.69116L4.35719 6.19725C4.4496 6.3142 4.59071 6.38208 4.73976 6.38127H4.73976C4.88964 6.38091 5.03091 6.31117 5.12234 6.19241L7.33546 3.28678C7.49861 3.07281 7.45742 2.7671 7.24345 2.60395C7.02949 2.4408 6.72377 2.482 6.56063 2.69596V2.69596L6.56547 2.69596Z" fill="white"/><defs><linearGradient id="paint0_linear_7949_16555" x1="16.534" y1="-13.7125" x2="-11.7162" y2="-4.58954" gradientUnits="userSpaceOnUse"><stop stop-color="%23071C35"/><stop offset="1" stop-color="%235B8BC5"/></linearGradient></defs></svg>') center center no-repeat;
  background-size: cover;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px; /* Adjust as needed */
}
