.servicemain {
  background-image: url('https://squarebric-media-files.squarebric.com/Serviceimg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
  width: 100%;

}

@media (max-width: 1024px) {
  .servicemain {
    width: 100%;
  }
}

.custom-tick-list {
  list-style-type: none; 
  padding: 0;
}

.custom-tick-list li {
  position: relative; 
  padding-left: 1.5em; 
  margin-bottom: 0.5em; 
}

.custom-tick-list li::before {
  content: '✔'; 
  position: absolute; 
  left: 0; 
  top: 0;
  font-size: 0.8em; 
  color: #2E4374; 
}

.text{
  font-size: 14px;
}

.text-responsive{
  font-size: 20px;
}


@media (max-width: 600px) {
  .text-responsive{
font-size: 5px;
  }
}
@media (max-width: 850px) {
  .servicemain {
    height: 50vh;
    width: 110%;
  }
}
.backgrounddimand {
  background-image: url('https://squarebric-media-files.squarebric.com/chair.jpg');

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height:'100%';
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .backgrounddimand {
    height: 32vh;
  }
}

@media (max-width: 600px) {
  .text {
    font-size: 8px;
  }
}
.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
}

.dropdown-menu-inner {
  padding: 10px 0;
}

.backgroundand {
  background-image: url('https://squarebric-media-files.squarebric.com/reviewbackground.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 600px;
}

@media (max-width: 650px) {
  .backgroundand {
    height:500px;
  }
}

@media (max-width: 1024px) {
  .backgroundand {
    height: 720px;
  }
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 90%;
}
@media (max-width: 600px) {
  .custom-dropdown {
    width: 70%;
  }
}

.selected-option {

  width: 100%;
   width: 160px;


  width: 100%;
    width: 160px;
cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 5px;
}


.selected-option .icon {
  margin-right: 8px;
}
@media (max-width: 600px) {
  .customvalues {
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .selected-option {
    margin-bottom: 10px;
  }
}
.icon {
  margin-right: 0px;
}

.arrow-icon {
  transition: transform 0.3s;
}

.arrow-icon {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: calc(100% + 0px);
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;

}

.option {


}

.option {


  width: 100%;
}

.option {
  padding: 10px;


  /* padding: 8px 30px; */


  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}



@media (max-width: 1024px) {
  .customvalues {
    width: 200px;
  }
}



